import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";

function UserTransfer() {
  const { isLoading, setIsLoading, isAuth, token, setDesktopBarOpen,
    setNavbarOpen, walletBalance} = useContext(storeContext);

  const navigate = useNavigate();
  //website url
  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;
  const [nextBtn, setNextBtn] = useState(false);

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }

    //automatically close navbar on page load
    setDesktopBarOpen(false);
    setNavbarOpen(false);
  }, [isAuth]);

  //form data
  const [formData, setFormData] = useState({
    userName: "",
    amount: "",
    transactionDetail: "",
    otp: "",
  });

  //extracting form data
  const { amount, userName, transactionDetail, otp } = formData;

  //changing state of form data
  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  //send money to in-app user
  async function sendMoney() {
    setIsLoading(true)
    try {
      const response = await fetch(`${API_URL}/wallet/transfer/app`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          recipientUserName: userName,
          transactionDetails: transactionDetail,
          amount: Number(amount),
          otp
        }),
      });

      const data = await response.json();

      if (response.status === 200) {
          toast.success(`${amount}Naira was successfully transferred`);
          setFormData({
              userName: "",
              amount: "",
              transactionDetail: "",
              otp: "",
            });
            setNextBtn(false);
            navigate("/wallet")
            setIsLoading(false);

        } 
        

       if (response.status !== 200) {
         if (data.msg) {
          setFormData({
            otp: "",
            userName: ""
          });
          setNextBtn(false);
           toast.error(data.msg);
         } 

         if (!data.msg) {
          setFormData({
            otp: "",
            userName: "",
          });
           data.map((dataError) => {
             toast.error(dataError.message);
           })
          setNextBtn(false);
         }
       }

         setFormData({
           otp: "",
         });
      setIsLoading(false);
    } catch (error) {
      console.log(error);    
      toast.error("Network error: try again")
    }
  }

  //after next
  function sendHandler(e) {
    e.preventDefault();
    setIsLoading(true);

    if (otp < 10000 || otp > 99999) {
        setFormData({
          otp: "",
        });
      toast.error("Your OTP needs to be 5 digits")
      setIsLoading(false);
      return;
    }

    //send money
    sendMoney();
    setIsLoading(false);
  }

  //submit for next - OTP
  function onSubmitHandler(e) {
    e.preventDefault();

    if (userName.trim() === "") {
       return toast.error("Enter a recipient username");
    }

    if (amount > walletBalance) {
      return toast.error("You cannot send more than your wallet Balance");
    }

    if (amount > 50000000) {
     return toast.error("You cannot send more than 50 million Naira");
    } else {
      setNextBtn(true);
    }
  }

  //undo next button
  function undoNext(e) {
    e.preventDefault();
    setNextBtn(false);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-lg">
        <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
          Send Money to Another User
        </h1>

        <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
          You can send Naira to another in-App user
        </p>

        {nextBtn === false ? (
          <>
            <form
              onSubmit={onSubmitHandler}
              className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
            >
              <p className="text-center text-lg font-medium">
                Fill in the Details
              </p>

              <div className="col-span-6">
                <label
                  htmlFor="UserName"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  Recipient NairaDOM userName{" "}
                </label>

                <input
                  type="text"
                  id="UserName"
                  value={userName}
                  required
                  placeholder="folorunsho21"
                  onChange={onChange}
                  name="userName"
                  className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                />
              </div>

              <div className="">
                <label
                  htmlFor="TransactionDetail"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  Transaction Details{" "}
                </label>

                <div className="">
                  <input
                    type="text"
                    id="TransactionDetail"
                    className="mt-1 w-full rounded-lg border-gray-200 bg-white text-gray-700 p-4 pe-12 text-sm shadow-sm"
                    placeholder="Daddy's gift for Xmas"
                    value={transactionDetail}
                    onChange={onChange}
                    name="transactionDetail"
                    required
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="Amount"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  Amount{" "}
                </label>

                <input
                  type="number"
                  id="Amount"
                  placeholder="30000"
                  value={amount}
                  required
                  onChange={onChange}
                  name="amount"
                  className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                />
              </div>

              <button
                type="submit"
                className="block w-full rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium "
              >
                Next
              </button>
            </form>
          </>
        ) : (
          <form
            onSubmit={sendHandler}
            className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
          >
            <div className="col-span-6 mt-3">
              <label
                htmlFor="otp"
                className="block text-sm font-bold text-white"
              >
                {" "}
                Your 5 Digit PIN{" "}
              </label>

              <input
                type="number"
                id="otp"
                value={otp}
                required
                onChange={onChange}
                name="otp"
                maxLength="5"
                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
              />
            </div>

            <p className="text-left text-sm text-gray-500">
              Forgot PIN?{" "}
              <Link className="underline" to="/resetotp">
                Reset
              </Link>
            </p>

            <button
              type="submit"
              className="block w-full rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium text-white"
            >
              Send
            </button>
            <button
              type="button"
              onClick={undoNext}
              className="block w-full rounded-lg bg-error px-5 py-3 text-sm font-medium text-white"
            >
              Back
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
export default UserTransfer;
