import { Link, useNavigate, useParams } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";

function RegisterReferral() {
  const { isLoading, setIsLoading, isAuth } = useContext(storeContext);

  const [country, setCountry] = useState("UK");

  //params
  const params = useParams();

  const referralID = params.id

  const updatedReferralCode = referralID.toUpperCase()

  const navigate = useNavigate();
  //website url
  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;

  useEffect(() => {
    if (isAuth) {
      navigate("/wallet");
    }
    setIsLoading(false);
  }, [isAuth]);

  function selectCountry(e) {
    setCountry(e.target.value);
  }

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    mobile: "",
    username: "",
    address: "",
    otp: "",
  });

  const {
    first_name,
    last_name,
    email,
    password,
    password_confirmation,
    mobile,
    otp,
    username,
    address
  } = formData;

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  //create Account
  async function createAccount() {
    try {
      const response = await fetch(`${API_URL}/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          firstname: first_name,
          lastname: last_name,
          phone: mobile,
          username,
          address,
          country,
          otp,
          affliateId: updatedReferralCode
        }),
      });

      const data = await response.json();

      if (response.status === 200) {
        toast.success("Account created successfully");
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          password_confirmation: "",
          mobile: "",
          username: "",
          address: "",
          country: "",
          otp: "",
          affliateCode: "",
        });
        navigate("/login");
      } else {
        toast.error(data[0].message);
      }

      setIsLoading(false);
    } catch (error) {
      toast.error(
        "Network error: We are unable to create an account for you. Try again"
      );
      console.log(error);
    }
  }

  function onSubmit(e) {
    e.preventDefault();

    if (otp < 10000 || otp > 99999) {
      toast.error("Your OTP needs to be 5 digits");
      return;
    }

    if (password !== password_confirmation) {
      toast.error("Passwords do not match");
    } else {
      setIsLoading(true);
      createAccount(first_name, last_name, email, mobile, password, otp);
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <section className="">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <section className="relative flex h-32 items-end bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6">
          <img
            alt="Night"
            src="https://images.pexels.com/photos/6778650/pexels-photo-6778650.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            className="absolute inset-0 h-full w-full object-cover opacity-80"
          />

          <div className="hidden lg:relative lg:block lg:p-12 ">
            <Link className="block text-white" to="/">
              <span className="sr-only">Home</span>
              <FaUserAlt className="text-5xl" />
            </Link>

            <h2 className="mt-6 text-2xl font-bold  sm:text-3xl md:text-4xl">
              Welcome to NairaDOM
            </h2>

            <p className="mt-4 leading-relaxed text-white">
              Sign up on our platform and sending money to Nigerians using your
              cryptocurrencies. Receive wallet transfers for free
            </p>
          </div>
        </section>

        <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
          <div className="max-w-xl lg:max-w-3xl">
            <div className="relative -mt-16 block lg:hidden">
              <Link
                className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-white sm:h-20 sm:w-20"
                to="/"
              >
                <span className="sr-only">Home</span>
                <FaUserAlt className="text-3xl " />
              </Link>

              <h1 className="mt-2 text-2xl font-bold  sm:text-3xl md:text-4xl">
                Welcome to NairaDOM
              </h1>

              <p className="mt-4 leading-relaxed text-gray-500">
                Sign up on our platform and sending money to Nigerians using
                your cryptocurrencies. Receive wallet transfers for free from
                other users.
              </p>
            </div>

            <form onSubmit={onSubmit} className="mt-8 grid grid-cols-6 gap-6">
              <>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="FirstName"
                    className="block text-sm font-medium text-white"
                  >
                    First Name
                  </label>

                  <input
                    type="text"
                    id="FirstName"
                    name="first_name"
                    value={first_name}
                    onChange={onChange}
                    required
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="LastName"
                    className="block text-sm font-medium text-white"
                  >
                    Last Name
                  </label>

                  <input
                    type="text"
                    id="LastName"
                    name="last_name"
                    value={last_name}
                    required
                    onChange={onChange}
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="Email"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Email{" "}
                  </label>

                  <input
                    type="email"
                    id="Email"
                    value={email}
                    required
                    onChange={onChange}
                    name="email"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="Username"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Username - must be unique{" "}
                  </label>

                  <input
                    type="text"
                    id="Username"
                    value={username}
                    onChange={onChange}
                    required
                    name="username"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                  <p className="text-sm">min of 3 characters and max of 20</p>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="affliateCode"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Referral Code{" "}
                  </label>

                  <input
                    type="text"
                    id="affliateCode"
                    defaultValue={updatedReferralCode}
                    disabled
                    placeholder="optional"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                  <p className="text-sm">5 digit Referred code</p>
                </div>

                <div className="col-span-6 ">
                  <label
                    htmlFor="Address"
                    className="block text-sm font-medium text-white"
                  >
                    Current Address
                  </label>

                  <input
                    type="text"
                    id="Address"
                    value={address}
                    onChange={onChange}
                    required
                    name="address"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="Mobile"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Mobile{" "}
                  </label>

                  <input
                    type="number"
                    id="Mobile"
                    value={mobile}
                    required
                    onChange={onChange}
                    name="mobile"
                    placeholder="+441172345678"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="Country"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Country{" "}
                  </label>

                  <select
                    id="Country"
                    required
                    onChange={selectCountry}
                    className="h-10 rounded border-gray-300 text-sm w-32 border"
                  >
                    <option>Select Country</option>
                    <option value="NG">Nigeria</option>
                    <option value="GH">Ghana</option>
                    <option value="FR">France</option>
                    <option value="CAD">Canada</option>
                    <option value="IE">Ireland</option>
                    <option value="US">United States</option>
                    <option value="GB">United Kingdom</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="ZA">South Africa</option>
                    <option value="QA">Qatar</option>
                    <option value="CY">Cyprus</option>
                    <option value="BE">Belgium</option>
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="Password"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Password{" "}
                  </label>

                  <input
                    type="password"
                    id="Password"
                    value={password}
                    onChange={onChange}
                    required
                    name="password"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                  <p className="text-sm">
                    8-30 characters, 1 lowercase, 1 uppercase, 1 numeric and 1
                    symbol
                  </p>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="PasswordConfirmation"
                    className="block text-sm font-medium text-white"
                  >
                    Password Confirmation
                  </label>

                  <input
                    type="password"
                    id="PasswordConfirmation"
                    value={password_confirmation}
                    onChange={onChange}
                    required
                    name="password_confirmation"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>
                {password_confirmation.trim().length >= 8 ? (
                  <div className="col-span-6 mt-3">
                    <label
                      htmlFor="otp"
                      className="block text-sm font-bold text-white"
                    >
                      {" "}
                      Create Your 5 Digit PIN Here{" "}
                    </label>

                    <input
                      type="number"
                      id="otp"
                      value={otp}
                      required
                      onChange={onChange}
                      name="otp"
                      maxLength="5"
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                    />
                    <p className="text-sm">OTP should be from 10000 - 99999</p>
                  </div>
                ) : null}
              </>

              <div className="col-span-6">
                <p className="text-sm text-gray-500">
                  By creating an account, you agree to our
                  <Link to="/" className="text-gray-700 underline">
                    {" "}
                    terms and conditions{" "}
                  </Link>
                  and
                  <Link to="/" className="text-gray-700 underline">
                    privacy policy
                  </Link>
                  .
                </p>
              </div>

              <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                <button className="inline-block shrink-0 rounded-md border border-secondary bg-secondary px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-accent focus:outline-none focus:ring active:text-accent">
                  Create an account
                </button>

                <p className="mt-4 text-sm text-gray-500 sm:mt-0">
                  Already have an account?
                  <Link to="/login" className="text-gray-700 underline">
                    Log in
                  </Link>
                  .
                </p>
              </div>
            </form>
          </div>
        </main>
      </div>
    </section>
  );
}
export default RegisterReferral;
