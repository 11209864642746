import Home from "../pages/Home";
import VerticalNav from "./VerticalNav";
import { useContext } from "react";
import storeContext from "../context/storeContext";

function AppSetup() {
  const { isAuth, desktopbarOpen } = useContext(storeContext);

  return (
    <>
      <div className="">
        {desktopbarOpen && isAuth ? <VerticalNav /> : null}
      </div>
    </>
  );
}
export default AppSetup;
