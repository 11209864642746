import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Modal from "react-modal";

// Check if the environment is the browser before setting the app element
if (typeof window !== 'undefined') {
  Modal.setAppElement('#root');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

