import CurrencyConverter from "../components/CurrencyConverter";
import CryptoConverter from "../components/CryptoConverter";
import { FaMoneyCheckDollar, FaMoneyBillTransfer } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import { GrTransaction } from "react-icons/gr";
import { FaDatabase } from "react-icons/fa";
import { TbArrowsTransferDown } from "react-icons/tb";
import Spinner from "../layout/Spinner";
import { FiActivity } from "react-icons/fi";
import dollarImg from "../images/dollar.jpg";
import investImg from "../images/invest.jpg";
import profile1Img from "../images/profile1.png";
import profile2Img from "../images/profile2.png";
import profile3Img from "../images/profile3.png";
import frontImg from "../images/front.png";
import broSam from "../images/broSam.jpg"

function Home() {
  const { isLoading, setIsLoading, isAuth, setDesktopBarOpen, setNavbarOpen } =
    useContext(storeContext);
  const navigate = useNavigate();
   const [comingSoon, setComingSoon] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (isAuth) {
      navigate("/wallet");
    }

    setDesktopBarOpen(false);
    setNavbarOpen(false);
    setIsLoading(false);
  }, [isAuth]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      {comingSoon ? (
        "Coming soon - Launch date is 20th April 2024"
      ) : (
        <div className=" w-screen">
          <section className="bg-white">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-16">
                <div className="md:py-24">
                  <h2 className="text-3xl font-bold sm:text-3xl text-secondary">
                    Grow Your Wealth in Dollars for Inflation-Beating Returns.
                  </h2>

                  <p className="mt-4 text-black">
                    Join our beloved Nigerian customers in building both local
                    and global economies! Partner with our carefully chosen
                    foreign businesses for rewarding returns.
                  </p>

                  <Link
                    to="/register"
                    className="mt-8 inline-block rounded bg-secondary px-12 py-3 text-sm font-medium  transition hover:bg-black focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Started Today
                  </Link>
                  <Link
                    to="/about"
                    className="mt-8 inline-block rounded bg-secondary px-12 ml-3 py-3 text-sm font-medium text-white transition hover:bg-black focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Learn
                  </Link>
                </div>
                <div className="hidden md:block  md:mx-auto ">
                  <img
                    alt=""
                    src={frontImg}
                    className="hidden md:block md:h-full md:rounded-3xl object-contain aspect-square w-full"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* <section className="bg-secondary">
            <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
              <h2 className="text-center text-4xl font-bold tracking-tight text-primary sm:text-5xl">
                Meet the great team working behind this project
              </h2>

              <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8">
                <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                  <div className="flex items-center gap-4">
                    <img
                      alt=""
                      src="https://taiwoolugbenga.vercel.app/assets/profile-Ab23sQ31.png"
                      className="size-14 rounded-full object-cover"
                    />

                    <div>
                      <div className="flex justify-center gap-0.5 text-green-500"></div>

                      <p className="mt-0.5 text-lg font-medium text-gray-900">
                        Olugbenga Taiwo, Software Engineer
                      </p>
                    </div>
                  </div>

                  <p className="mt-4 text-gray-700">
                    Olugbenga Taiwo leads the software engineering team of this
                    innovative fintech solution.
                  </p>
                </blockquote>
                <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                  <div className="flex items-center gap-4">
                    <img
                      alt=""
                      src={broSam}
                      className="size-14 rounded-full object-cover"
                    />

                    <div>
                      <div className="flex justify-center gap-0.5 text-green-500"></div>

                      <p className="mt-0.5 text-lg font-medium text-gray-900">
                        Olorunda Samuel, HR Manager
                      </p>
                    </div>
                  </div>

                  <p className="mt-4 text-gray-700">
                    Olorunda Samuel Leads the Human Resource team.
                  </p>
                </blockquote>
                <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                  <div className="flex items-center gap-4">
                    <img
                      alt=""
                      src="https://taiwoolugbenga.vercel.app/assets/profile-Ab23sQ31.png"
                      className="size-14 rounded-full object-cover"
                    />

                    <div>
                      <div className="flex justify-center gap-0.5 text-green-500"></div>

                      <p className="mt-0.5 text-lg font-medium text-gray-900">
                        Amaka Kingsley, Head of marketing
                      </p>
                    </div>
                  </div>

                  <p className="mt-4 text-gray-700">
                    Mrs Amaka manages the marketing team. 
                  </p>
                </blockquote>
              </div>
            </div>
          </section> */}

          <section className="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2 sm:items-center">
            <div className="hidden md:my-4 md:pl-6 md:block">
              <img
                alt="image"
                src={dollarImg}
                className="h-full w-full object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-ss-[30px] md:h-[calc(100%_-_4rem)] md:rounded-ss-[60px]"
              />
            </div>
            <div className="p-8 md:p-12 lg:px-16 lg:py-24">
              <div className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
                <h2 className="text-2xl font-bold text-gray-900 md:text-3xl">
                  Building Financial Resilience: Embrace Dollar Savings Today
                </h2>

                <p className="hidden text-gray-500 md:mt-4 md:block">
                  With NairaDOM, you can seize arbitrage opportunities with
                  competitive rates, potentially yielding significant gains.
                  Protect your savings from inflation by diversifying into USD,
                  ensuring long-term purchasing power. Don't wait; secure your
                  financial future now.
                </p>

                <div className="mt-4 md:mt-8">
                  <Link
                    to="/register"
                    className="inline-block rounded bg-secondary px-12 py-3 text-sm font-medium text-white transition hover:bg-secondary focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Started Today
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-secondary">
            <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
              <h2 className="text-center text-4xl font-bold tracking-tight text-primary sm:text-5xl">
                Read trusted reviews from our users
              </h2>

              <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-8">
                <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                  <div className="flex items-center gap-4">
                    <img
                      alt=""
                      src={profile1Img}
                      className="size-14 rounded-full object-cover"
                    />

                    <div>
                      <div className="flex justify-center gap-0.5 text-green-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <p className="mt-0.5 text-lg font-medium text-gray-900">
                        Ibukun, Nigeria
                      </p>
                    </div>
                  </div>

                  <p className="mt-4 text-gray-700">
                    NairaDom is a valuable App that helps with savings and
                    investment while evading the possible inflation that affects
                    the Nigerian currency.
                  </p>
                </blockquote>

                <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                  <div className="flex items-center gap-4">
                    <img
                      alt=""
                      src={profile3Img}
                      className="size-14 rounded-full object-cover"
                    />

                    <div>
                      <div className="flex justify-center gap-0.5 text-green-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <p className="mt-0.5 text-lg font-medium text-gray-900">
                        Paul, UK
                      </p>
                    </div>
                  </div>

                  <p className="mt-4 text-gray-700">
                    NairaDom has helped me send money to my family easily from
                    the UK!
                  </p>
                </blockquote>

                <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                  <div className="flex items-center gap-4">
                    <img
                      alt=""
                      src={profile2Img}
                      className="size-14 rounded-full object-cover"
                    />

                    <div>
                      <div className="flex justify-center gap-0.5 text-green-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <p className="mt-0.5 text-lg font-medium text-gray-900">
                        Lola, Nigeria
                      </p>
                    </div>
                  </div>

                  <p className="mt-4 text-gray-700">
                    NairaDom is the best. I can receive money from clients
                    easily from home and abroad! It is a dream come true in
                    Nigeria
                  </p>
                </blockquote>
              </div>
            </div>
          </section>

          <section className=" bg-white">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="mx-auto max-w-lg text-center">
                <h2 className="text-3xl text-secondary font-bold sm:text-4xl">
                  How are we able to do this?
                </h2>

                <p className="mt-4 text-gray-500">
                  Learn about our mode of operations. We make sure everything is
                  plain to our users.
                </p>
              </div>

              <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                <div className="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-black hover:shadow-black">
                  <FaMoneyCheckDollar className="text-3xl" />

                  <h2 className="mt-4 text-xl font-bold text-secondary">
                    No hidden fees
                  </h2>

                  <p className="mt-1 text-sm text-gray-500">
                    We do not hide any fees. Our Nigerian payment processor
                    charges 2% (1000NGN cap) for deposit and 70NGN for
                    withdrawal fees. All other fees are shown on our platform
                  </p>
                </div>
                <div className="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-black hover:shadow-black">
                  <GrTransaction className="text-3xl" />

                  <h2 className="mt-4 text-xl font-bold text-secondary">
                    Track your transactions easily
                  </h2>

                  <p className="mt-1 text-sm text-gray-500">
                    You can track your transactions on the app easily
                  </p>
                </div>
                <div className="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-black hover:shadow-black">
                  <FaDatabase className="text-3xl" />

                  <h2 className="mt-4 text-xl font-bold text-secondary">
                    Data stored on the Cloud
                  </h2>

                  <p className="mt-1 text-sm text-gray-500">
                    Your data are stored on the cloud and so you can be sure of
                    almost 99% uptime of our servers
                  </p>
                </div>
                <div className="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-black hover:shadow-black">
                  <FaMoneyBillTransfer className="text-3xl" />

                  <h2 className="mt-4 text-xl font-bold text-secondary">
                    Nigerian Bank transfers
                  </h2>

                  <p className="mt-1 text-sm text-gray-500">
                    You can withdraw your funds to your bank account in Nigeria.
                    We expect you to receive the funds within 15 mins - 24 hours
                  </p>
                </div>
                <div className="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-black hover:shadow-black">
                  <TbArrowsTransferDown className="text-3xl" />

                  <h2 className="mt-4 text-xl font-bold text-secondary">
                    Free In-App transfers
                  </h2>

                  <p className="mt-1 text-sm text-gray-500">
                    You can send funds to any any NairaDom user for free.
                    Transfer is instant
                  </p>
                </div>
                <div className="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-black hover:shadow-black">
                  <FiActivity className="text-3xl" />

                  <h2 className="mt-4 text-xl font-bold text-secondary">
                    Track your login activities
                  </h2>

                  <p className="mt-1 text-sm text-gray-500">
                    You can track login activities, time and even devices used
                    to login to your account
                  </p>
                </div>
              </div>

              <div className="mt-12 text-center">
                <Link
                  to="/register"
                  className="inline-block rounded bg-secondary px-12 py-3 text-sm font-medium text-white transition hover:bg-secondary focus:outline-none focus:ring focus:ring-yellow-400"
                >
                  Get Started Today
                </Link>
              </div>
            </div>
          </section>
          <div className="bg-secondary">
            <section className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="mx-auto max-w-lg text-center">
                <h2 className="text-3xl font-bold sm:text-4xl">
                  Frequently asked questions?
                </h2>

                <p className="my-4  text-gray-500">
                  Get answers to popular questions asked by our users.
                </p>
              </div>
              <div className="space-y-4">
                <details
                  className="group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden"
                  open
                >
                  <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                    <h2 className="text-lg font-medium text-gray-900">
                      What is NairaDOM and what can i do on the platform?
                    </h2>

                    <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>

                  <p className="mt-4 leading-relaxed text-gray-700">
                    NairaDom is not a bank but an intermediary platform that
                    allows you to convert your naira to USD, invest in Stable US
                    Businesses and earn returns, send & receive money and
                    withdraw your funds to any bank in Nigeria. We partner with
                    a lot of businesses to achieve this. This cuts off middlemen
                    saving you money.
                  </p>
                </details>

                <details className="group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden">
                  <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                    <h2 className="text-lg font-medium text-gray-900">
                      How do you make sure our investments are safe?
                    </h2>

                    <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>

                  <p className="mt-4 leading-relaxed text-gray-700">
                    Actually, all investments are risky one way or the other
                    because of a million things that could go wrong. One can
                    only minimize risk to the lowest possible means and this we
                    do by investing in stable handpicked US businesses to gives
                    us returns quaterly, bi-annually and annually! <br /> <br />{" "}
                    We have many criteria for choosing a business but one of our
                    requirements is that such business must have been
                    profitably-existing for at least 5 years.
                  </p>
                </details>
                <details className="group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden">
                  <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                    <h2 className="text-lg font-medium text-gray-900">
                      Why should i invest in USD rather than naira?
                    </h2>

                    <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>

                  <p className="mt-4 leading-relaxed text-gray-700">
                    Saving or Investment in USD guarantees that what you have
                    worked for over time isn't lost by the economic inflation!
                    If you had converted 1,000,000 Naira to 1,000USD a year ago
                    when 1 USD was 1,000 NGN, it would be worth 1,700,000 naira
                    as at this moment not including the interests
                  </p>
                </details>

                <details className="group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden">
                  <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                    <h2 className="text-lg font-medium text-gray-900">
                      What are your interest rates?
                    </h2>

                    <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>

                  <p className="mt-4 leading-relaxed text-gray-700">
                    We decided to find and invest in only US stable businesses
                    which gives guaranteed returns up to 15% yearly.
                    <br /> <br /> From our polls, 100% of users prefer
                    guaranteed/safe investments!
                  </p>
                </details>
                <details className="group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden">
                  <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                    <h2 className="text-lg font-medium text-gray-900">
                      Is the business registered?
                    </h2>

                    <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>

                  <p className="mt-4 leading-relaxed text-gray-700">
                    Yes, NairaDOM is one of the many digital products of our
                    registered business.
                  </p>
                </details>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
}
export default Home;
