import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import { CiBank } from "react-icons/ci";
import Modal from "react-modal";
import { MdCallReceived } from "react-icons/md";

function Recipient() {
  const {
    isLoading,
    setIsLoading,
    isAuth,
    token,
    banks,
    fetchBanks,
    fetchAllRecipients,
    recipients,
    setDesktopBarOpen,
    setNavbarOpen,
    fetchProfile,
    userProfile,
  } = useContext(storeContext);
  const [bank_code, setBank_code] = useState("");
  const [bankName, setBankName] = useState("");
  //check if transaction is empty
  const isEmpty = Object.keys(userProfile).length === 0;

  const navigate = useNavigate();
  //website url
  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }
    //fetch banks and recipients on load
    fetchBanks();
    fetchAllRecipients();
    fetchProfile(); // fetch profile
    setDesktopBarOpen(false);
    setNavbarOpen(false);
  }, [isAuth]);

  //for modal
  const [isOpen, setIsOpen] = useState(true);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [formData, setFormData] = useState({
    account_number: "",
    phone: userProfile.phone,
    bvn: "",
    email: userProfile.email,
  });

  const { account_number, phone, email, bvn } = formData;

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  function onSubmitHandler(e) {
    e.preventDefault();

    setIsLoading(true);

    if (account_number.trim().length !== 10) {
      setIsLoading(false);
      return toast.error("Account number can only be 10 digits");
    }

    //check if bvn
    if (
      userProfile.country === "NG" &&
      userProfile.bvnVerificationStatus !== "true"
    ) {
      if (bvn.trim().length !== 11) {
        setIsLoading(false);
        return toast.error("BVN can only be 11 digits");
      }
    }

    if (bankName.trim().length === 0) {
      setIsLoading(false);
      return toast.error("Please select a bank");
    }

    //create recipient
    createRecipient();
  }

  //create recipient on server
  async function createRecipient() {
    setIsLoading(true);
    try {
      //checking BVN
      if (
        userProfile.bvnVerificationStatus !== "true" &&
        userProfile.country === "NG"
      ) {
        console.log("checking bvn");
        const response = await fetch(`${API_URL}/account/verifybvn`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            bankCode: bank_code,
            bvn,
            accountNumber: account_number,
          }),
        });

        const data = await response.json();

        console.log(data);

        if (response.status === 200) {
          toast.success("BVN Verification in Progress. Check Your profile for update");
          setFormData({
            account_number: "",
            bvn: "",
          });
          setBank_code("");
          fetchAllRecipients();
          navigate("/profile");
        }

        if (response.status !== 200) {
          setFormData({
            account_number: "",
            bvn: "",
          });
          setBank_code("");

          if (response.status === 400) {
            toast.error(
              "BVN or Account Number is incorrect"
            );
          }
        }

        setIsLoading(false);
        return;
      }

      // To add recipients
      const response = await fetch(`${API_URL}/transfer/recipient`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bank_code,
          account_number,
          phone,
          email,
          bank_name: bankName,
        }),
      });

      const data = await response.json();

      if (response.status === 200) {
        toast.success("Recipient created successfully");
        setFormData({
          account_number: "",
          phone: "",
        });
        setBank_code("");
        fetchAllRecipients();
      }

      if (response.status !== 200) {
        setFormData({
          account_number: "",
        });
        setBank_code("");
        if (response.status === 401) {
          toast.error(
            "Unable to get account details. Kindly check the account number and retry again"
          );
        }

        //handing error
        if (response.status === 400) {
          data.map((dataError) => {
            toast.error(dataError.message);
          });
        }
      }

      setIsLoading(false);
    } catch (error) {
       toast.error(
         "Network error"
       );
      console.log(error);
    }
  }

  //set bank code
  function selectBank(e) {
    banks.map((bank) => {
      if (bank.code === e.target.value) {
        return setBankName(bank.name);
      }
    });
    setBank_code(e.target.value);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 pb-16 sm:px-6 lg:px-8 container">
      <div className="mx-auto max-w-lg">
        {isEmpty === false &&
        userProfile.bvnVerificationStatus !== "true" &&
        userProfile.country === "NG" ? (
          <>
            <Modal isOpen={isOpen} className="border w-80 mx-auto">
              <div
                role="alert"
                className="rounded border-s-4 border-red-500 bg-red-50 p-4"
              >
                <strong className="block font-medium text-red-800">
                  {" "}
                  BVN is Required For Our Nigerian Users{" "}
                </strong>

                <p className="mt-2 text-sm text-red-700">
                  You are required to provide your BVN as a means of
                  identification to get a virtual account for funding your Naira
                  wallet.
                </p>
                <div
                  className="btn mt-3 hover:bg-secondary"
                  onClick={closeModal}
                >
                  Start Now <MdCallReceived className="text-lg" />
                </div>
              </div>
            </Modal>
          </>
        ) : null}
        <>
          <form
            onSubmit={onSubmitHandler}
            className="mb-0 mt-3 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8 "
          >
            {userProfile.bvnVerificationStatus !== "true" &&
            userProfile.country === "NG" ? (
              <>
                <p className="text-center text-lg font-medium">
                  Verify your BVN and add your account for withdrawal
                </p>
                <div className="col-span-6">
                  <label
                    htmlFor="Account_number"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Your Account Number{" "}
                  </label>

                  <input
                    type="number"
                    id="Account_number"
                    value={account_number}
                    required
                    placeholder="0234219865"
                    onChange={onChange}
                    name="account_number"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="SelectBank"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Choose Your Bank{" "}
                  </label>

                  <select
                    id="SelectBank"
                    required
                    onChange={selectBank}
                    className="h-10 rounded border-gray-300 text-sm w-32 border"
                  >
                    <option>Select Bank</option>
                    {Object.keys(banks).length === 0
                      ? null
                      : banks.map((bank, index) => {
                          return (
                            <option key={index} value={bank.code}>
                              {bank.name}
                            </option>
                          );
                        })}
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="Bvn"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Your BVN{" "}
                  </label>

                  <input
                    type="number"
                    id="Bvn"
                    value={bvn}
                    required
                    onChange={onChange}
                    name="bvn"
                    placeholder="1003217643"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>
              </>
            ) : (
              <>
                <p className="text-center text-lg font-medium">
                  Add your bank account to withdraw at any time.
                </p>

                <div className="col-span-6">
                  <label
                    htmlFor="Account_number"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Your Account Number{" "}
                  </label>

                  <input
                    type="number"
                    id="Account_number"
                    value={account_number}
                    required
                    placeholder="0234219865"
                    onChange={onChange}
                    name="account_number"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="SelectBank"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Choose Bank{" "}
                  </label>

                  <select
                    id="SelectBank"
                    required
                    onChange={selectBank}
                    className="h-10 rounded border-gray-300 text-sm w-32 border"
                  >
                    <option>Select Bank</option>
                    {banks.map((bank, index) => {
                      return (
                        <option key={index} value={bank.code}>
                          {bank.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="Email"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Your Email{" "}
                  </label>

                  <input
                    type="text"
                    id="Email"
                    value={email}
                    required
                    defaultValue={userProfile.email}
                    onChange={onChange}
                    name="email"
                    placeholder="demo@gmail.com"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="Mobile"
                    className="block text-sm font-medium text-white"
                  >
                    {" "}
                    Your Mobile{" "}
                  </label>

                  <input
                    type="number"
                    id="Mobile"
                    value={phone}
                    defaultValue={userProfile.phone}
                    required
                    onChange={onChange}
                    name="phone"
                    placeholder="0803217643"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>
              </>
            )}

            <button
              type="submit"
              className="block w-full rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium "
            >
              {userProfile.country === "NG" &&
              userProfile.bvnVerificationStatus !== "true"
                ? "Verify Now"
                : "Create Recipient"}
            </button>
          </form>
          <Link className="btn mt-2" to="/banktransfer">
            Transfer to saved recipients <CiBank className="text-xl" />
          </Link>
        </>
      </div>

      <div className="py-2">Saved Bank Accounts</div>

      {recipients.length === 0 ? (
        <div>No saved Nigerian Bank accounts found. Add one</div>
      ) : (
        <div className="overflow-x-auto mb-4">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
            <thead className="ltr:text-left rtl:text-right">
              <tr>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Account Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Account Number
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Bank Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Phone Number
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {recipients.map((recipient, index) => {
                return (
                  <tr className="odd:bg-gray-50" key={index}>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 ">
                      {recipient.fullName}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {recipient.accountNumber}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {recipient.bankName}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {recipient.phone}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
export default Recipient;
