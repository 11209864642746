import { useState } from "react";
import { GiShop } from "react-icons/gi";
import { Link, NavLink } from "react-router-dom";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import { FaSignInAlt, FaSignOutAlt, FaUser } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Home from "../pages/Home";
import VerticalNav from "./VerticalNav";
import myLogo from "../images/nairadomlogo.png"; 

function Navbar() {
  
  const {
    isAuth,
    desktopbarOpen,
    setDesktopBarOpen,
    navbarOpen,
    setNavbarOpen,
  } = useContext(storeContext);

  function checkNavbar() {
    if (!navbarOpen) {
      setNavbarOpen(true)
    } else {
      setNavbarOpen(false);
    }
  }
  function checkDesktopbar() {
    if (!desktopbarOpen) {
      setDesktopBarOpen(true);
    } else {
      setDesktopBarOpen(false);
    }
  }

  return (
    <div className=" dat">
      <div>
        <section className="relative mx-auto">
          <nav className="flex justify-between bg-primary text-secondary w-screen">
            <div className="px-5 xl:px-12 py-4 flex w-full items-center">
              <div className="flex-none px-2 xl:ml-7">
                

                <Link to="/">
                  <img src={myLogo} alt="logo" className=" h-11" />
                
                </Link>
              </div>
              {isAuth ? (
                <div className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12">
                  {""}
                </div>
              ) : (
                <ul className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12">
                  <li>
                    <NavLink
                      activeclassname="active"
                      className="hover:text-gray-200"
                      to="/"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeclassname="active"
                      className="hover:text-gray-200"
                      to="/about"
                    >
                      About
                    </NavLink>
                  </li>
                </ul>
              )}

              <div className="hidden md:flex items-center space-x-5 mr-3">
                {isAuth ? null : (
                  <Link
                    className="flex items-center hover:text-gray-200"
                    to="/register"
                  >
                    <FaUserAlt className="text-2xl mr-3" /> Register
                  </Link>
                )}
                {isAuth ? (
                  <>
                    <Link
                      className="flex items-center hover:text-gray-200"
                      to="/logout"
                    >
                      Logout
                      <FaSignOutAlt className="text-2xl ml-3" />
                    </Link>
                    <a
                      className="navbar-burger self-center mr-12"
                      href="#"
                      onClick={checkDesktopbar}
                    >
                      {desktopbarOpen ? (
                        <IoMdClose className="text-3xl" />
                      ) : (
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 hover:text-gray-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M4 6h16M4 12h16M4 18h16"
                            />
                          </svg>
                        </div>
                      )}
                    </a>
                  </>
                ) : (
                  <Link
                    className="flex items-center hover:text-gray-200"
                    to="login"
                  >
                    <FaSignInAlt className="text-2xl mr-3" /> Login
                  </Link>
                )}
              </div>
            </div>
            <>
              {isAuth ? (
                <Link
                  className="xl:hidden lg:hidden md:hidden flex mr-6 items-center"
                  to="/logout"
                >
                  {" "}
                  Logout
                  <FaSignOutAlt className="text-2xl ml-3" />
                </Link>
              ) : (
                <>
                  <Link
                    className="xl:hidden lg:hidden md:hidden flex mr-6 items-center"
                    to="/login"
                  >
                    {" "}
                    Login
                    <FaSignInAlt className="text-2xl ml-3" />
                  </Link>
                  <Link
                    className="xl:hidden lg:hidden md:hidden flex mr-6 items-center"
                    to="/register"
                  >
                    <FaUserAlt className="text-2xl mr-3 ml-3" /> Register
                  </Link>
                </>
              )}
            </>
            {isAuth ? (
              <a
                className="navbar-burger self-center mr-12 xl:hidden lg:hidden md:hidden"
                href="#"
                onClick={checkNavbar}
              >
                {navbarOpen ? (
                  <IoMdClose className="text-3xl" />
                ) : (
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 hover:text-gray-200"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </div>
                )}
              </a>
            ) : null}
          </nav>
        </section>
        <div>{isAuth && navbarOpen ? <VerticalNav /> : null}</div>
      </div>
    </div>
  );
}
export default Navbar;
