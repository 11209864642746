import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Login() {
  const {
    isLoading,
    setIsLoading,
    setIsAuth,
    isAuth,
    userEmail,
    setUserEmail,
  } = useContext(storeContext);
  const [email, setEmail] = useState("");
  const [scrambledPassword, setScrambledPassword] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const toggle = () => setShowPassword(!showPassword);

  //website url
  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;

  function onChangeEmail(e) {
    setEmail(e.target.value);
  }
  function onChangePassword(e) {
    setPassword(e.target.value);
  }

  //generate random values
  function generateRandomString(length) {
    // Function to generate a random alphanumeric string of specified length
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

   function scrambleString(str) {
     // Generate random alphanumeric strings of length 7
     const randomPrefix = generateRandomString(7);
     const randomSuffix = generateRandomString(7);

     // Reverse the original string
     const reversedStr = str.split("").reverse().join("");

     // Combine the random strings and reversed string
     const editedString = randomPrefix + reversedStr + randomSuffix;
     return setScrambledPassword(editedString) ;
   }

  //if auth is true, navigate to user dashboard
  useEffect(() => {
    if (isAuth) {
      navigate("/wallet");
    }
    if (!isAuth) {
      setIsLoading(false);
    }
  }, [isAuth]);

  //logging user in
  async function loginHandler(e) {
    e.preventDefault();
    try {
      
       setIsLoading(true);
       if (password.trim().length > 5) {
         scrambleString(password); //scramble password

        if (scrambledPassword !== "") {
          
          const response = await fetch(`${API_URL}/auth/login-request`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
              password: scrambledPassword,
            }),
          });
 
          setUserEmail(email); //set user email globally
          const data = await response.json();
          // console.log(data);
          if (response.status === 200) {
            navigate("/loginverification");
          } else {
            toast.error(data);
            setEmail("");
            setPassword("");
            setScrambledPassword("")
          }
        }

         setIsLoading(false);
       } else {
         toast.error("Error.password must be at least 5 characters");
         setIsLoading(false);
       }
    } catch (error) {
       toast.error(
         "Network error: We are unable to log you in at the moment"
       );
    }
   
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg">
          <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
            Sign In today
          </h1>

          <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
            Login to start making and receiving payments on our App. Remember,
            you can also send money to any Nigerian bank account and check your
            transactions in real time.
          </p>

          <form
            onSubmit={loginHandler}
            className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
          >
            <p className="text-center text-lg font-medium">
              Sign in to your account
            </p>

            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>

              <div className="relative">
                <input
                  type="email"
                  className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                  placeholder="Enter email"
                  value={email}
                  onChange={onChangeEmail}
                  required
                />

                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gold-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>

              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                  placeholder="Enter password"
                  value={password}
                  onChange={onChangePassword}
                  required
                />

                <span
                  className="absolute inset-y-0 end-0 grid place-content-center px-4"
                  onClick={toggle}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>

            <button
              type="submit"
              className="block w-full rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium text-white"
            >
              Sign in
            </button>

            <p className="text-center text-sm">
              <Link className="underline" to="/resetpassword">
                Forgotten password?
              </Link>
            </p>

            <p className="text-center text-sm text-gray-500">
              No account?{" "}
              <Link className="underline" to="/register">
                Sign up
              </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
}
export default Login;
