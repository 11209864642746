
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  FacebookIcon,
} from "react-share";
import Invite from "../components/Invite";

function Profile() {
  const {
    isLoading,
    setIsLoading,
    isAuth,
    fetchProfile,
    userProfile,
    setDesktopBarOpen,
    setNavbarOpen,
  } = useContext(storeContext);
  const navigate = useNavigate();


  useEffect(() => {
    setIsLoading(true);
    if (isAuth) {
     
      fetchProfile()
    }
    if (!isAuth) {
      return navigate("/login");
    }

    setIsLoading(false);
    setDesktopBarOpen(false);
    setNavbarOpen(false);
  }, []);

  console.log(userProfile);
  if (isLoading) {
    return <Spinner />;
  }
  

  return (
    <div className="my-10 mx-16">
      <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
        Your Profile
      </h1>

      <p className="mx-auto mt-4 max-w-md text-center text-gray-500 mb-4">
        See the details about your profile with us. Cheers
      </p>
      <div className="flow-root rounded-lg border border-gray-100 py-3 shadow-sm">
        <dl className="-my-3 divide-y divide-gray-100 text-sm">
          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium ">First Name</dt>
            <dd className="text-primary sm:col-span-2">
              {userProfile.firstName}
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Last Name</dt>
            <dd className="text-gray-700 sm:col-span-2">
              {userProfile.lastName}
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium ">Email</dt>
            <dd className="text-white sm:col-span-2">{userProfile.email}</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Country</dt>
            <dd className="text-gray-700 sm:col-span-2">
              {userProfile.country}
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium ">Address</dt>
            <dd className="text-white sm:col-span-2">{userProfile.address}</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Customer Type</dt>
            <dd className="text-gray-700 sm:col-span-2">
              {userProfile.customerType}
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium ">Document verification status</dt>
            <dd className="text-white sm:col-span-2">
              {userProfile.verificationStatus === "false"
                ? "Not started"
                : userProfile.verificationStatus}
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Phone Number</dt>
            <dd className="text-gray-700 sm:col-span-2">{userProfile.phone}</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium ">UserName</dt>
            <dd className="text-white/65 sm:col-span-2">
              {userProfile.userName}
            </dd>
          </div>

          {userProfile.country === "NG" ? (
            <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4 text-black">
              <dt className="font-medium ">BVN Verification Status</dt>
              <dd className="text-black sm:col-span-2">
                {userProfile.bvnVerificationStatus === "failed"
                  ? "Failed - Retry"
                  : userProfile.bvnVerificationStatus === "false"
                  ? "Not started"
                  : "Verified"}
              </dd>
            </div>
          ) : null}

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium ">Referral Code</dt>
            <dd className="text-white/65 sm:col-span-2">
              {userProfile.referralCode === null ||
              userProfile.referralCode === ""
                ? "No Referral Code"
                : userProfile.referralCode}
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4 text-black">
            <dt className="font-medium ">Affliate ID</dt>
            <dd className="text-black sm:col-span-2">
              {userProfile.affliateId === null || userProfile.affliateId === ""
                ? "No Affliate"
                : userProfile.affliateId}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
export default Profile;
