import { motion } from "framer-motion";

function About() {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.3 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: 0.2, duration: 0.8 }}
      className="container about px-10 mb-10"
    >
      <h1 className="text-4xl mb-4 ">Learn About Us</h1>
      <p className="text-white">
        NairaDOM allows you protect Your Naira, grow Your wealth while investing
        in Dollars for Inflation-Beating Returns.
        <br /> <br /> Our vision is to build both local and global economies!
        With our investment approach, we partner with carefully chosen US
        businesses, contributing to their growth and success. As they thrive, so
        do our investors, with rewarding returns at the end of the investment
        period. <br /> <br />
        Together, we're building a brighter financial future for everyone
        involved, both at home and abroad.
      </p>
      
    </motion.div>
  );
}
export default About;
