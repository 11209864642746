import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";

function LoginActivities() {
    const navigate = useNavigate()
  const {
    isLoading,
    setIsLoading,
    isAuth,
    setDesktopBarOpen,
    setNavbarOpen,
    fetchAccountActivities,
    accountActivities,
  } = useContext(storeContext);

  useEffect(() => {
    setIsLoading(true);

    //fetch account activities automatically
    if (isAuth) {
      fetchAccountActivities()
    }
    if (!isAuth) {
      return navigate("/login");
    }

    setDesktopBarOpen(false);
    setNavbarOpen(false);
    setIsLoading(false);
  }, [])

   if (isLoading) {
     return <Spinner />;
   }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 container">
      <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
        Your Account Activities
      </h1>

      <p className="mx-auto mt-4 max-w-md text-center text-gray-500 mb-4">
        See the details about your account activities. Cheers
      </p>
      <div className="overflow-x-auto mb-4">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Activity
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Device Information
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {accountActivities.map((activity, index) => {
              return (
                <tr className="odd:bg-gray-50" key={index}>
                  <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 ">
                    {activity.activity}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {activity.ip}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default LoginActivities;
