import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useJwt } from "react-jwt";
import { functions } from "lodash";
import { useNavigate } from "react-router-dom";

const storeContext = createContext();

export const StoreProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  const [desktopbarOpen, setDesktopBarOpen] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const [banks, setBanks] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [accountActivities, setAccountActivities] = useState([]);
  const [paymentLink, setPaymentLink] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState({});
  const [convertedAmount, setConvertedAmount] = useState("");
  const [sellRate, setSellRate] = useState("");
  const [buyRate, setBuyRate] = useState("");
  const [wallet, setWallet] = useState("ngn");
  const [availableAmount, setAvailableAmount] = useState(0);
  const [investmentPlans, setInvestmentPlans] = useState([]);
  const [investmentPlan, setInvestmentPlan] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [bankDetail, setBankDetail] = useState({});

  //api url
  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;

  //get token from localstorage
  const token = localStorage.getItem("token");
  const { isExpired } = useJwt(token);
  const [userEmail, setUserEmail] = useState("");

  //check if loggedIn
  async function loginChecker() {
    try {
      if (token === null) {
        setIsAuth(false);
        return;
      }
      if (isExpired) {
        // Handle invalid or expired token
        localStorage.removeItem("token");
        setIsAuth(false);

        return;
      }

      if (!isExpired) {
        setIsAuth(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //fetch all products on app load
  useEffect(() => {
    loginChecker(); //check if user has loggedIn device
  }, [isExpired]);

  async function createCryptoInvoice(transactionId) {
    try {
      const response = await fetch(
        `${API_URL}/payment/checkout/transaction/${transactionId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      console.log(data);
      setPaymentLink(data.url);
    } catch (error) {
      console.log(error);
    }
  }

  //get a Transaction
  async function fetchOneTransaction(transRef) {
    try {
      const response = await fetch(`${API_URL}/transaction/${transRef}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setTransaction(data);
    } catch (error) {
      toast.error(
        "Network error: We are unable to get this transaction at the moment. Reload page"
      );
    }
  }

  //get a Subscription
  async function fetchOneSubscription(subID) {
    try {
      const response = await fetch(
        `${API_URL}/investment/subscription/${subID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      setSubscription(data);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get this investment detail at the moment.Reload page"
       );
      console.log(error);
    }
  }

  //get a Investment plan
  async function fetchInvestmentPlan(planId) {
    try {
      const response = await fetch(`${API_URL}/investment/plan/${planId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setInvestmentPlan(data);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get this investment plan at the moment. Reload page"
       );
      console.log(error);
    }
  }

  //fetch balance
  async function fetchBalance(currency) {
    try {
      const response = await fetch(`${API_URL}/wallet/${currency}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setWalletBalance(data.balance);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get this wallet balance at the moment. Reload page"
       );
      console.log(error);
    }
  }

  //fetch account activities
  async function fetchAccountActivities() {
    try {
      const response = await fetch(`${API_URL}/users/activity`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setAccountActivities(data.activities);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get your wallet activities at the moment. Reload page"
       );
      console.log(error);
    }
  }

  //fetch all transactions
  async function fetchTransactions(currency) {
    try {
      const response = await fetch(`${API_URL}/transaction/all/${currency}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setTransactions(data);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get your transactions at the moment. Reload page"
       );
      console.log(error);
    }
  }

  //fetch all subscriptions
  async function fetchSubscriptions(currency) {
    try {
      const response = await fetch(`${API_URL}/investment/subscription/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setSubscriptions(data);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get all your investments at the moment. Reload page"
       );
      console.log(error);
    }
  }

  //fetch investment plans
  async function fetchInvestmentPlans() {
    try {
      const response = await fetch(`${API_URL}/investment/plan/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      setInvestmentPlans(data);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get all investment plans at the moment. Reload page"
       );
      console.log(error);
    }
  }

  //fetch user profile
  async function fetchProfile() {
    try {
      const response = await fetch(`${API_URL}/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setUserProfile(data)
      return data
    } catch (error) {
       toast.error(
         "Network error: We are unable to get your profile at the moment. Reload page"
       );
      console.log(error);
    }
  }
  //fetch user profile
  async function fetchBankAccount() {
    try {
      const response = await fetch(`${API_URL}/account`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setBankDetail(data);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get your dedicated bank account at the moment. Reload page"
       );
      console.log(error);
    }
  }

  //fetch all recipients
  async function fetchAllRecipients() {
    try {
      const response = await fetch(`${API_URL}/transfer/recipient`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setRecipients(data.recipients);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get all your recipients at the moment. Reload page"
       );
      console.log(error);
    }
  }

  //fetch currency rates
  async function fetchCurrencyRate(amount, currency) {
    try {
      const response = await fetch(
        `${API_URL}/payment/rates/${amount}/currency/${currency}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      setSellRate(data.result.sellRate);
      setBuyRate(data.result.buyRate);
      setConvertedAmount(data.result.convertedAmount);
      setAvailableAmount(data.result.availableAmount);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get currency rates at the moment. Reload page"
       );
      console.log(error);
    }
  }

  // fetchBanks()

  //fetch banks
  async function fetchBanks() {
    try {
      const response = await fetch(`${API_URL}/payment/banks`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      setBanks(data.banks);
    } catch (error) {
       toast.error(
         "Network error: We are unable to get all banks at the moment. Reload page"
       );
      console.log(error);
    }
  }

  const contextObj = {
    token,
    isAuth,
    setIsAuth,
    desktopbarOpen,
    setDesktopBarOpen,
    navbarOpen,
    setNavbarOpen,
    isLoading,
    setIsLoading,
    loginChecker,
    userEmail,
    setUserEmail,
    walletBalance,
    fetchBalance,
    fetchBanks,
    banks,
    fetchAllRecipients,
    recipients,
    fetchProfile,
    userProfile,
    fetchAccountActivities,
    accountActivities,
    paymentLink,
    setPaymentLink,
    createCryptoInvoice,
    fetchTransactions,
    transactions,
    fetchOneTransaction,
    transaction,
    convertedAmount,
    setConvertedAmount,
    sellRate,
    buyRate,
    fetchCurrencyRate,
    wallet,
    setWallet,
    availableAmount,
    fetchInvestmentPlans,
    investmentPlans,
    fetchInvestmentPlan,
    investmentPlan,
    subscriptions,
    fetchSubscriptions,
    subscription,
    fetchOneSubscription,
    bankDetail,
    fetchBankAccount,
  };

  return (
    <storeContext.Provider value={contextObj}>{children}</storeContext.Provider>
  );
};

export default storeContext;
