import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import { IoMdCheckmark } from "react-icons/io";
import _ from "lodash";

function InvestmentPlans() {
  const {
    isLoading,
    setIsLoading,
    isAuth,
    setDesktopBarOpen,
    setNavbarOpen,
    fetchInvestmentPlans,
    investmentPlans,
  } = useContext(storeContext);

  const navigate = useNavigate();
  //website url
  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;

  useEffect(() => {
    setIsLoading(true);

    //fetch investment plans
    if (isAuth) {
      fetchInvestmentPlans();
    }

    if (!isAuth) {
      navigate("/login");
    }

    //automatically close navbar on page load
    setDesktopBarOpen(false);
    setNavbarOpen(false);
    setIsLoading(false);
  }, [isAuth]);

  //lodash to sort
  const sortedInvestedPlans = _.orderBy(investmentPlans, ["id"], ["desc"]);



  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className=" max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 mx-auto">
      <div className=" ">
        <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
          Invest in dollars and get returns in dollars
        </h1>

        <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
          Lend dollars to our handpicked foreign businesses and get
          guaranteed interest on your investments
        </p>

        <div className=" w-full px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:items-center md:gap-8">
            {Object.keys(investmentPlans).length === 0
              ? null
              : sortedInvestedPlans.map((item, index) => {
                return (
                  <div className="rounded-2xl border border-white p-6 shadow-sm ring-1 ring-white sm:order-last sm:px-8 lg:p-12" key={index}>
                    <div className="text-center">
                      <h2 className="text-lg font-medium">
                        {item.name} Plan
                        <span className="sr-only">Plan</span>
                      </h2>

                      <p className="mt-2 sm:mt-4">
                        <strong className="text-3xl font-bold text-white sm:text-4xl">
                          {" "}
                          {(((item.interestRate * 12)/item.duration) * 100).toFixed(2)}%{" "}
                        </strong>

                        <span className="text-sm font-medium text-white/85">
                          per annum
                        </span>
                      </p>
                    </div>

                    <ul className="mt-6 space-y-2">
                      <li className="flex items-center gap-1">
                        <IoMdCheckmark />

                        <span className="text-white/75">
                          {" "}
                          Daily interest in USD{" "}
                        </span>
                      </li>
                      <li className="flex items-center gap-1">
                        <IoMdCheckmark />

                        <span className="text-white/75"> ROI with capital guaranteed </span>
                      </li>
                      <li className="flex items-center gap-1">
                        <IoMdCheckmark />

                        <span className="text-white/75">
                          {" "}
                          Secure transaction{" "}
                        </span>
                      </li>
                      <li className="flex items-center gap-1">
                        <IoMdCheckmark />

                        <span className="text-white/75">
                          {item.duration} months duration
                        </span>
                      </li>
                      <li className="flex items-center gap-1">
                        <IoMdCheckmark />

                        <span className="text-white/75">
                          {item.minAmount} - {item.maxAmount}USD available for investments
                        </span>
                      </li>
                      <li className="flex items-center gap-1">
                        <IoMdCheckmark />

                        <span className="text-white/75">
                          {" "}
                          Automated return of interest & capital to USD wallet
                          at plan expiry{" "}
                        </span>
                      </li>
                      <li className="flex items-center gap-1">
                        <IoMdCheckmark />

                        <span className="text-white/75">
                          {" "}
                          Withdraw USD interest to NGN wallet at current USD
                          market rate{" "}
                        </span>
                      </li>
                    </ul>

                    <Link
                      to={`/investmentplan/${item.id}`}
                      className="mt-8 block rounded-full border border-black bg-white px-12 py-3 text-center text-sm font-medium text-secondary hover:bg-black hover:ring-1 hover:ring-primary focus:outline-none focus:ring active:text-white hover:text-white"
                    >
                      Invest Now
                    </Link>
                  </div>
                )
              })}
            
          </div>
        </div>
      </div>
    </div>
  );
}
export default InvestmentPlans;
