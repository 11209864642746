import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import { Link } from "react-router-dom";

function ResetOtp() {
  const { isLoading, setIsLoading, isAuth, setUserEmail } =
    useContext(storeContext);
  const [email, setEmail] = useState("");
  const [hideResetBtn, setHideResetBtn] = useState(false);
  const navigate = useNavigate();

  //website url
  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;

  function onChangeEmail(e) {
    setEmail(e.target.value);
  }

  //if auth is true, navigate to user dashboard
  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }
  }, []);

  //logging user in
  async function resetHandler(e) {
    e.preventDefault();

    try {
      setIsLoading(true);
      if (email.trim().length !== "") {
        const response = await fetch(`${API_URL}/reset/password-request`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
          }),
        });

        setUserEmail(email); //set user email globally

        const data = await response.json();
        // console.log(data);
        if (response.status === 200) {
          navigate("/resetotpverification");
        } else {
          toast.error(data);
          setEmail("");
        }
        setIsLoading(false);
      } else {
        toast.error("Please input an email");
        setIsLoading(false);
      }
    } catch (error) {
       toast.error(
         "Network error: try again"
       );
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg">
          <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
            Reset your secure PIN
          </h1>

          <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
            Did you forget your secure PIN? Let's help you reset your PIN to continue
            sending money to your recipients. We will send you a token to your
            email to reset your PIN
          </p>

          <form
            onSubmit={resetHandler}
            className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
          >
            <p className="text-center text-lg font-medium">
              Let's help you reset your PIN
            </p>

            <div>
              <label htmlFor="email" className="sr-only">
                We need you to confirm your email
              </label>

              <div className="col-span-6">
                <label
                  htmlFor="Email"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  We need you to confirm your email{" "}
                </label>

                <input
                  type="email"
                  id="Email"
                  value={email}
                  required
                  onChange={onChangeEmail}
                  name="email"
                  className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                />
              </div>
            </div>

            <button
              type="submit"
              className="block w-full rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium text-white"
            >
              Send Reset Token
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default ResetOtp;
