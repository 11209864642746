import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import PaymentRedirect from "./PaymentRedirect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function UserVerification() {
  const [date, setDate] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState(null);

  //handle file upload
  const handleFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
  };


  const {
    isLoading,
    setIsLoading,
    isAuth,
    token,
    setDesktopBarOpen,
    setNavbarOpen,
    fetchProfile,
    userProfile,
  } = useContext(storeContext);

  //navigation
  const navigate = useNavigate();
  //website url
  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    } else {
        fetchProfile()
    }

    

    //automatically close navbar on page load
    setDesktopBarOpen(false);
    setNavbarOpen(false);
  }, [isAuth]);

  //form data
  const [formData, setFormData] = useState({
    address: "",
  });

  //extracting form data
  const { address } = formData;

  //changing state of form data
  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  //upload file to server
  async function uploadFileHandler(e) {
    e.preventDefault()

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {

        if (selectedFile === null) {
           return toast.error(`You need to upload your ID card`); 
        }

      //file must not be more than 500kb
      if (selectedFile.size > 520000) {
        return toast.error(`You cannot upload a document more than 500kb`);
      }

      console.log(selectedFile.type);
      //file must not be pdf/jpg/png
      if (
        selectedFile.type !== "image/jpeg" &&
        selectedFile.type !== "image/jpg" &&
        selectedFile.type !== "image/png"
      ) {
        return toast.error(`You can only upload png/jpg/jpeg`);
      }

      setIsLoading(true)
      //create transaction on server
      const response = await fetch(`${API_URL}/users/idcard`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();

      console.log(data);

      //if successful, then call payment link
      if (response.status === 200) {
        setSelectedFile(null)
        toast.success("ID uploaded successfully. Now,send in your details below");
      }

      if (response.status !== 200) {
        if (data.msg) {
           toast.error(data.msg);
        }

        if (!data.msg) {
           toast.error(data);
        }
      }
     return setIsLoading(false)
    } catch (error) {
     toast.error("Network error: try again")
    }
  }

  //create Invoice
  async function verifyUser() {
    try {
      //create transaction on server
      const response = await fetch(`${API_URL}/users/verify`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          dateOfBirth: date,
          proofOfAddress: address,
        }),
      });

      const data = await response.json();

      //if successful, then call payment link
      if (response.status === 200) {
        toast.success("Details sent successfully");
        navigate("/profile");
      }

      if (response.status !== 200) {
        if (data.msg) {
          toast.error(data.msg);
        }

      }

      setFormData({
        address: "",
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  //submit
  async function onSubmitHandler(e) {
    e.preventDefault();

    if (address.trim() === "") {
      return toast.error(`Address cannot be empty`);
    }

   
    setIsLoading(true);
    //send to server
    await verifyUser();
    setIsLoading(false);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-lg">
        <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
          Start your Verification and send funds in Naira
        </h1>

        <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
          Verfication is less than 24hrs
        </p>

        {userProfile.verificationStatus === "false" ? (
          <>
            <form
              onSubmit={uploadFileHandler}
              className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
            >
              <div className="col-span-6 sm:col-span-3 text-center">
                <h3 className="text-sm font-medium text-white pb-2">
                  Upload your ID card
                </h3>
                <input type="file" onChange={handleFileUpload} />
              </div>
              <button
                type="submit"
                className="block w-64 rounded-lg bg-white px-5 text-black py-3 text-sm font-medium mx-auto"
              >
                Upload ID
              </button>
            </form>
            <hr />

            <form
              onSubmit={onSubmitHandler}
              className="mb-0 mt-2 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
            >
              <p className="text-center text-lg font-medium">
                Fill in the Details
              </p>

              <div className="col-span-6 sm:col-span-3 text-center">
                <label
                  htmlFor="Address"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  Address to be verified{" "}
                </label>

                <input
                  type="text"
                  id="Address"
                  placeholder="your address"
                  value={address}
                  required
                  onChange={onChange}
                  name="address"
                  className="mt-1 w-64 rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                />
              </div>

              <div className="col-span-6 sm:col-span-3 text-center">
                <label
                  htmlFor="DateOfBirth"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  Select your date of birth{" "}
                </label>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                />
              </div>

              <button
                type="submit"
                className="block w-64 rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium mx-auto"
              >
                Submit
              </button>
            </form>
          </>
        ) : <div className="mt-6">Your verification status is currently {userProfile.verificationStatus === "true" ? "Completed" : "processing"} . Cheers</div>
        }
      </div>
    </div>
  );
}
export default UserVerification;
