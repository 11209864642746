import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";

function InvestmentPlanItem() {
  const {
    isLoading,
    setIsLoading,
    isAuth,
    token,
    setDesktopBarOpen,
    setNavbarOpen,
    walletBalance,
    fetchCurrencyRate,
    fetchBalance,
    fetchInvestmentPlan,
    investmentPlan
  } = useContext(storeContext);

  const navigate = useNavigate();
  //website url

  //params
  const params = useParams();


  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;
  const [nextBtn, setNextBtn] = useState(false);

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }

    //automatically close navbar on page load
    fetchCurrencyRate(1, "usd");
    fetchBalance("usd");
    fetchInvestmentPlan(params.id);
    setDesktopBarOpen(false);
    setNavbarOpen(false);
  }, [isAuth]);


  //form data
  const [formData, setFormData] = useState({
    amount: "",
    otp: "",
  });

  //extracting form data
  const { amount, otp } = formData;

  //changing state of form data
  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  //deposit money into usd wallet
  async function subscribeHandler() {
    try {
      const response = await fetch(`${API_URL}/investment/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          amount: Number(amount),
          planId: Number(params.id),
          otp,
        }),
      });

      const data = await response.json();

    

      if (response.status === 200) {
        toast.success(`You have successfully subscribed to the ${investmentPlan.name} plan`);
        setFormData({
          amount: "",
          otp: "",
        });
        setNextBtn(false);
        navigate("/subscriptions");
        setIsLoading(false);
      }

      if (response.status !== 200) {
        if (data.msg) {
          setFormData({
            otp: "",
          });
          setNextBtn(false);
          toast.error(data.msg);
        }

        if (!data.msg) {
          setFormData({
            otp: "",
          });
          setNextBtn(false);
          toast.error(data);
        }
      }

      setFormData({
        otp: "",
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      toast.error(
        "Network error: try again"
      )
    }
  }

  //after next
  function sendHandler(e) {
    e.preventDefault();
    setIsLoading(true);

    if (otp < 10000 || otp > 99999) {
      setFormData({
        otp: "",
      });
      setIsLoading(false);
      toast.error("Your OTP needs to be 5 digits");
      return;
    }

    //subscribe the user
    subscribeHandler();
    setIsLoading(false);
  }

  //submit for next - OTP
  function onSubmitHandler(e) {
    e.preventDefault();

    if (
      amount < investmentPlan.minAmount ||
      amount > investmentPlan.maxAmount
    ) {
      return toast.error("A min of 5 - 100,000 USD is only allowed");
    }

    if (amount > walletBalance) {
      return toast.error("Insufficient USD balance");
    } else {
      setNextBtn(true);
    }
  }

  //undo next button
  function undoNext(e) {
    e.preventDefault();
    setNextBtn(false);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-lg">
        <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
          Invest today in {investmentPlan.name} plan
        </h1>

        <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
          Lend {investmentPlan.minAmount + "-" + investmentPlan.maxAmount} USD
          to our handpicked traders and get{" "}
          {(((investmentPlan.interestRate * 12) / investmentPlan.duration) * 100).toFixed(2)}%
          guaranteed return on interest in dollars per year.
        </p>

        <h2 className="text-center text-sm font-extralight text-white sm:text-lg mt-2">
          Status: {investmentPlan.isActive ? "Active" : "Not active"}
        </h2>
        <h2 className="text-center text-sm font-extralight text-white sm:text-lg mt-2">
          Duration: {investmentPlan.duration} months
        </h2>

        {nextBtn === false ? (
          <>
            <form
              onSubmit={onSubmitHandler}
              className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
            >
              <p className="text-center text-lg font-medium">
                Fill in the Details
              </p>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="Amount"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  Investment Amount in USD{" "}
                </label>

                <input
                  type="number"
                  id="Amount"
                  placeholder="200"
                  value={amount}
                  required
                  onChange={onChange}
                  name="amount"
                  className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                />
              </div>

              <button
                type="submit"
                className="block w-full rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium "
              >
                Next
              </button>
            </form>
          </>
        ) : (
          <form
            onSubmit={sendHandler}
            className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
          >
            <div className="col-span-6 mt-3">
              <label
                htmlFor="otp"
                className="block text-sm font-bold text-white"
              >
                {" "}
                Your 5 Digit Secure PIN{" "}
              </label>

              <input
                type="number"
                id="otp"
                value={otp}
                required
                onChange={onChange}
                name="otp"
                maxLength="5"
                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
              />
            </div>

            <p className="text-left text-sm text-gray-500">
              Forgot PIN?{" "}
              <Link className="underline" to="/resetotp">
                Reset
              </Link>
            </p>

            <button
              type="submit"
              className="block w-full rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium text-white"
            >
              Invest
            </button>
            <button
              type="button"
              onClick={undoNext}
              className="block w-full rounded-lg bg-error px-5 py-3 text-sm font-medium text-white"
            >
              Back
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
export default InvestmentPlanItem;
