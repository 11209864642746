
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
  TelegramIcon,
  LinkedinIcon
} from "react-share";

function Invite() {
  const {
    isLoading,
    setIsLoading,
    isAuth,
    fetchProfile,
    userProfile,
    setDesktopBarOpen,
    setNavbarOpen,
  } = useContext(storeContext);
  const navigate = useNavigate();


  useEffect(() => {
    setIsLoading(true);
    if (isAuth) {
     
      fetchProfile()
    }
    if (!isAuth) {
      return navigate("/login");
    }

    setIsLoading(false);
    setDesktopBarOpen(false);
    setNavbarOpen(false);
  }, []);


  const shareUrl = `https://nairadom.com/register/${userProfile.referralCode}`;
  if (isLoading) {
    return <Spinner />;
  }
  

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-lg">
        <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
          Invite your friends
        </h1>

        <p className="mx-auto my-4 max-w-md text-center">
          You earn 5% interest on their investments earnings in dollars. You
          must be verified to earn interest
        </p>

        <article className="hover:animate-background rounded-xl bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 p-0.5 shadow-xl transition hover:bg-[length:400%_400%] hover:shadow-sm hover:[animation-duration:_4s]">
          <div className="rounded-[10px] bg-white p-4 !pt-20 sm:p-6">
            <p className="text-secondary ">Referral code</p>

            <p className="text-5xl text-center font-bold text-black">
              {userProfile.referralCode}
            </p>

            <div className="mt-4 flex flex-wrap gap-1">
              <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-xs text-purple-600">
                {userProfile.firstName}
              </span>

              <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-xs text-purple-600">
                {userProfile.lastName}
              </span>
            </div>
          </div>
        </article>

        <p className="mx-auto my-4 max-w-md text-center text-primary/65">
          Click on your favourite social media handle to start sharing
        </p>

        <div className="my-4 mt-6">
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={32} round className="mr-2" />
          </FacebookShareButton>
          <WhatsappShareButton
            url={shareUrl}
            title={`I ,${
              userProfile.firstName + " " + userProfile.lastName
            } is inviting you to NairaDom where you can grow your income and earn dividends`}
            separator="  "
          >
            <WhatsappIcon size={32} round className="mr-2" />
          </WhatsappShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={`I ,${
              userProfile.firstName + " " + userProfile.lastName
            } is inviting you to NairaDom where you can grow your income and earn dividends`}
          >
            <TwitterIcon size={32} round className="mr-2" />
          </TwitterShareButton>
          <LinkedinShareButton
            url={shareUrl}
            title={`I ,${
              userProfile.firstName + " " + userProfile.lastName
            } is inviting you to NairaDom where you can grow your income and earn dividends`}
          >
            <LinkedinIcon size={32} round className="mr-2" />
          </LinkedinShareButton>
          <EmailShareButton
            url={shareUrl}
            title={`I ,${
              userProfile.firstName + " " + userProfile.lastName
            } is inviting you to NairaDom where you can grow your income and earn dividends`}
          >
            <EmailIcon size={32} round className="mr-2" />
          </EmailShareButton>
          <TelegramShareButton
            url={shareUrl}
            title={`I ,${
              userProfile.firstName + " " + userProfile.lastName
            } is inviting you to NairaDom where you can grow your income in USD, pay bills, buy airtime and earn dividends`}
          >
            <TelegramIcon size={32} round className="mr-2" />
          </TelegramShareButton>
        </div>
      </div>
    </div>
  );
}
export default Invite;
