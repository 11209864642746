import { Link, useNavigate } from "react-router-dom";
import storeContext from "../context/storeContext";
import { toast } from "react-toastify";
import { useContext } from "react";
import { useEffect } from "react";
import { useJwt } from "react-jwt";

function Logout() {
  const { setIsAuth, setDesktopBarOpen, desktopbarOpen, setNavbarOpen } =
    useContext(storeContext);

  const navigate = useNavigate();

  //logout if not validated
  const token = localStorage.getItem("token");
  const { isExpired } = useJwt(token);

  useEffect(() => {
    try {
      //reset navigation
      setDesktopBarOpen(false);
      setNavbarOpen(false);


      if (token === null) {
        navigate("/login");
        setIsAuth(false);
        return;
      }

      if (isExpired || !token) {
        // Handle invalid or expired token
        localStorage.removeItem("token");
        setIsAuth(false);
        navigate("/login");

        return;
      }
    } catch (error) {
      console.log(token)
    }
  }, []);

  function logOut() {
    localStorage.removeItem("token");
    setIsAuth(false);
    navigate("/login"); //go back to home on logout
    toast.success("You are logged out");
  }

  return (
    <div>
      <div className="rounded-lg bg-secondary p-8 shadow-2xl">
        <h2 className="text-lg font-bold">Are you sure you want to logout?</h2>

        <p className="mt-2 text-sm text-gray-500">
          Doing this means you have to login again to access your account, are
          you 100% sure it's OK?
        </p>

        <div className="mt-4 flex gap-2">
          <Link
            onClick={logOut}
            to="/"
            className="rounded bg-error px-4 py-2 text-sm font-medium text-secondary"
          >
            Yes, I'm sure
          </Link>

          <Link
            to="/wallet"
            className="rounded bg-gray-50 px-4 py-2 text-sm font-medium text-gray-600"
          >
            Go to dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Logout;
