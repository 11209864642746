import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import storeContext from "../context/storeContext";

function PaymentRedirect({ link }) {
  const { setPaymentLink } = useContext(storeContext);

   const navigate = useNavigate();


  function resetPaymentLink() {
    setPaymentLink("");
    navigate("/wallet")
  }

  setTimeout(() => {
    
  }, 5000)

  return (
    <section className="rounded-3xl shadow-2xl">
      <div className="p-8 text-center sm:p-12">
        <p className="text-sm font-semibold uppercase tracking-widest text-white">
          Your transaction is now pending
        </p>

        <h2 className="mt-6 text-3xl font-bold">
          You will be redirected on another tab for you to make a deposit to your wallet!
        </h2>

        <a
          className="mt-8 inline-block w-full rounded-full bg-pink-600 py-4 text-sm font-bold text-white shadow-xl"
          href={link}
          target="_blank"
          onClick={resetPaymentLink}
          rel="noopener noreferrer"
        >
          Deposit Now
        </a>
      </div>
    </section>
  );
}
export default PaymentRedirect;
