import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import PaymentRedirect from "./PaymentRedirect";

function SubscriptionItem() {
  const navigate = useNavigate();
  //params
  const params = useParams();

  const {
    isLoading,
    setIsLoading,
    isAuth,
    setDesktopBarOpen,
    setNavbarOpen,
    subscription,
    fetchOneSubscription,
  } = useContext(storeContext);

  //check if transaction is empty
  const isEmpty = Object.keys(subscription).length === 0;


  useEffect(() => {
    setIsLoading(true);

    //fetch account activities automatically
    if (isAuth) {
      fetchOneSubscription(params.id);
    }
    if (!isAuth) {
      return navigate("/login");
    }

    setDesktopBarOpen(false);
    setNavbarOpen(false);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="my-10 mx-16">
      <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
        Investment Details
      </h1>

      <p className="mx-auto mt-4 max-w-md text-center text-gray-500 mb-4">
        See the details about your investment
      </p>
      {isEmpty ? (
        <Spinner />
      ) : (
        <div className="flow-root rounded-lg border border-gray-100 py-3 shadow-sm">
          <dl className="-my-3 divide-y divide-gray-100 text-sm">
            <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium">Subscription Plan</dt>
              <dd className="text-primary sm:col-span-2 text-wrap break-words">
                {subscription.investmentPlan.name}
              </dd>
            </div>

            <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium text-gray-900">Amount Invested</dt>
              <dd className="text-gray-700 sm:col-span-2">
                {subscription.amount} {""}
                {subscription.investmentPlan.currency}
              </dd>
            </div>

            <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium ">Daily Interest</dt>
              <dd className="text-white sm:col-span-2">
                {subscription.dailyInterest} {""}
                {subscription.investmentPlan.currency}
              </dd>
            </div>

            <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium text-gray-900">Duration</dt>
              <dd className="text-gray-700 sm:col-span-2">
                {subscription.investmentPlan.duration} months
              </dd>
            </div>

            <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium ">Details</dt>
              <dd className="text-white sm:col-span-2">
                {subscription.investmentPlan.description}
              </dd>
            </div>

            <div className="grid grid-cols-1 gap-1 p-3 bg-black sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium  ">Expected Total Amount</dt>
              <dd className="sm:col-span-2 text-white">
                {subscription.dailyInterest *
                  30 *
                  subscription.investmentPlan.duration +
                  subscription.amount}{" "}
                {""}
                {subscription.investmentPlan.currency}
              </dd>
            </div>

            <div className="grid grid-cols-1 gap-1 p-3 bg-black sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium  ">Cumulative Interest</dt>
              <dd className="sm:col-span-2 text-white">
                {subscription.returnedInterest}
              </dd>
            </div>

            <div className="grid grid-cols-1 gap-1 p-3 bg-black sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium  ">Start Date</dt>
              <dd className="text-white sm:col-span-2">
                {new Date(subscription.startDate).toLocaleString("en-US", {
                  timeZone: "Africa/Lagos",
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                })}
              </dd>
            </div>
            <div className="grid grid-cols-1 gap-1 p-3 bg-black sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium ">Subscription Status</dt>
              <dd className="text-white sm:col-span-2">
                {subscription.status}
              </dd>
            </div>
            <div className="grid grid-cols-1 gap-1 p-3 bg-black sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium ">
                Interest Payment to Wallet Status
              </dt>
              <dd className="text-white sm:col-span-2">
                {subscription.interestPaid
                  ? "All interest with capital has been paid back into USD wallet"
                  : "Not yet paid into USD wallet"}
              </dd>
            </div>

            <div className="grid grid-cols-1 gap-1 p-3 bg-black sm:grid-cols-3 sm:gap-4">
              <dt className="font-medium ">End Date</dt>
              <dd className="text-white sm:col-span-2">
                {new Date(subscription.endDate).toLocaleString("en-US", {
                  timeZone: "Africa/Lagos",
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                })}
              </dd>
            </div>
          </dl>
        </div>
      )}

      <div className="flex mt-5 justify-center">
        <div className="pr-4">
          <Link
            to="/subscriptions"
            className="block rounded bg-gray-700 px-5 py-3 text-sm text-gray-100 transition hover:bg-gray-600"
          >
            Back to all Investments
          </Link>
        </div>
      </div>
    </div>
  );
}
export default SubscriptionItem;
