import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function ResetOtpVerification() {
  const {
    isLoading,
    setIsLoading,
    setIsAuth,
    isAuth,
    userEmail,
    setUserEmail,
    token
  } = useContext(storeContext);
  const navigate = useNavigate();

  //website url
  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;


  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
    emailToken: ""
    
  });

  const {
    password,
    password_confirmation,
    emailToken
  } = formData;

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }


  //if auth is true, navigate to user dashboard
  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }
  }, []);

  //logging user in
  async function resetHandler(e) {
    e.preventDefault();
    
    try {
      setIsLoading(true);
     
      if (password < 10000 || password > 99999) {
        toast.error("Your OTP needs to be 5 digits starting from 10000");
        setIsLoading(false);
        return;
      }


      if (password !== password_confirmation) {
        toast.error("OTP do not match");
        setIsLoading(false);
        return;
      }

       if (emailToken < 10000 || emailToken > 99999) {
         toast.error("Your OTP needs to be 5 digits");
         setIsLoading(false);
         return;
       }

      if (userEmail.trim().length !== "") {
        const response = await fetch(`${API_URL}/reset/otp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            email: userEmail,
            newOtp: password,
            token: emailToken,
          }),
        });

        const data = await response.json();

        if (response.status === 200) {
          toast.success("Token reset successfully");
          navigate("/wallet");
          setIsLoading(false);
        } else {
          data.map((dataError) => {
            toast.error(dataError.message);
          });
          setFormData({
            emailToken: "",
            password: "",
            password_confirmation: "",
          });
          setIsLoading(false);
        }
        
      } else {
        toast.error("No email available");
        setIsLoading(false);
      }

      setIsLoading(false)
    } catch (error) {
      console.log(error);
toast.error("Network error: try again");
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg">
          <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
            Reset your PIN Here Finally.
          </h1>

          <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
            Be ready with your token from your email to reset your PIN.
          </p>

          <form
            onSubmit={resetHandler}
            className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
          >
            <p className="text-center text-lg font-medium">
              Let's reset your PIN
            </p>

            <div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="Password"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  New PIN{" "}
                </label>

                <input
                  type="number"
                  id="Password"
                  value={password}
                  onChange={onChange}
                  required
                  name="password"
                  className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="PasswordConfirmation"
                  className="block text-sm font-medium text-white"
                >
                  Retype the PIN
                </label>

                <input
                  type="number"
                  id="PasswordConfirmation"
                  value={password_confirmation}
                  onChange={onChange}
                  required
                  name="password_confirmation"
                  className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                />
              </div>

              {password_confirmation.trim().length === 5 ? (
                <div className="col-span-6 mt-3">
                  <label
                    htmlFor="emailToken"
                    className="block text-sm font-bold text-white"
                  >
                    {" "}
                    Your Email Reset Token{" "}
                  </label>

                  <input
                    type="number"
                    id="emailToken"
                    value={emailToken}
                    required
                    onChange={onChange}
                    name="emailToken"
                    maxLength="5"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                  />
                </div>
              ) : null}
            </div>

            <button
              type="submit"
              className="block w-full rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium text-white"
            >
              Reset PIN
            </button>

            
          </form>
        </div>
      </div>
    </>
  );
}
export default ResetOtpVerification;
