import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import _ from "lodash";

function Subscriptions({ currency }) {
  const navigate = useNavigate();
  const {
    isLoading,
    setIsLoading,
    isAuth,
    setDesktopBarOpen,
    setNavbarOpen,
    fetchSubscriptions,
    subscriptions,
  } = useContext(storeContext);

  //lodash to sort
  const sortedSubscriptions = _.orderBy(subscriptions, ["id"], ["desc"]);

  useEffect(() => {
    setIsLoading(true);

    //fetch account activities automatically
    if (isAuth) {
      fetchSubscriptions();
    }
    if (!isAuth) {
      return navigate("/login");
    }

    setDesktopBarOpen(false);
    setNavbarOpen(false);
    setIsLoading(false);
  }, []);



  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-6 sm:px-6 lg:px-8 container">
      Your Investments Overview
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Investment Plan
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Date of Investment
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                 Amount Invested
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Investment Status
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Cumulative Interest
              </th>
              <th className="px-4 py-2"></th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {Object.keys(subscriptions).length === 0
              ? null
              : sortedSubscriptions.map((item, index) => {
                  const convertedTime = new Date(item.createdAt).toLocaleString(
                    "en-US",
                    {
                      timeZone: "Africa/Lagos",
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    }
                  );
                  return (
                    <tr key={index}>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        {item.investmentPlan.name} Plan
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {convertedTime}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {item.amount} {""}
                        {item.investmentPlan.currency}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {item.status}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {item.returnedInterest +
                          " " +
                          item.investmentPlan.currency}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Link
                          to={`/subscription/${item.id}`}
                          className="inline-block rounded bg-secondary px-4 py-2 text-xs font-medium text-white hover:bg-secondary"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default Subscriptions;
