import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";

function BankTransfer() {
  const {
    isLoading,
    setIsLoading,
    isAuth,
    token,
    recipients,
    fetchAllRecipients,
    setDesktopBarOpen,
    setNavbarOpen,
    walletBalance,
    wallet,
    setWallet,
    fetchBalance,
  } = useContext(storeContext);
  const [recipientID, setRecipientID] = useState("");

  const navigate = useNavigate();
  //website url
  const API_URL = process.env.REACT_APP_NAIRADOM_API_URL;
  const [nextBtn, setNextBtn] = useState(false);

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }

    //fetch all recipients
    fetchAllRecipients();

    //fetch balance
    fetchBalance("ngn")

    //automatically close navbar on page load
    setDesktopBarOpen(false);
    setNavbarOpen(false);
  }, [isAuth]);

  const [formData, setFormData] = useState({
    amount: "",
    transactionDetail: "",
    otp: "",
  });

  const { amount, transactionDetail, otp } = formData;

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  function undoNext(e) {
    e.preventDefault();
    setNextBtn(false);
  }

  //send money
  async function sendMoney() {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/wallet/withdraw`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          recipientId: parseInt(recipientID),
          transactionDetails: transactionDetail,
          amount: Number(amount),
          otp,
        }),
      });

      const data = await response.json();

     

      if (response.status === 200) {
        toast.success(`${amount}Naira was successfully transferred`);
        setFormData({
          amount: "",
          transactionDetail: "",
          otp: "",
        });
        setRecipientID("");
        setWallet("ngn")
        navigate("/wallet");
        setNextBtn(false);
        setIsLoading(false);
      }

      if (response.status !== 200) {
        if (data.msg) {
          setFormData({
            otp: "",
          });
          setNextBtn(false);
          setRecipientID("");
          toast.error(data.msg);
        }

        if (!data.msg) {
          data.map((dataError) => {
            toast.error(dataError.message);
          });
          setFormData({
            otp: "",
          });
          setNextBtn(false);
          setRecipientID("");
        }
      }

      setIsLoading(false);
    } catch (error) {
     toast.error(
       "Network error: try again"
     );
      console.log(error);
    }
  }

  //after next
  function sendHandler(e) {
    e.preventDefault();
    setIsLoading(true);

    if (otp < 10000 || otp > 99999) {
      toast.error("Your OTP needs to be 5 digits");
      setIsLoading(false);
      return;
    }
    

    //send money
    sendMoney();

    setIsLoading(false);
  }

  //submit for next - OTP
  function onSubmitHandler(e) {
    e.preventDefault();

    if (recipientID === "Select Recipient" || recipientID === "") {
      toast.error("Please select a recipient");
      return;
    }

    if (amount > walletBalance) {
      return toast.error("You cannot send more than your wallet Balance");
    }

    if (amount < 2000 || amount > 500000) {
      toast.error("Amount must be 2000NGN - 500000NGN");
      setIsLoading(false);
      return;
    }

      setIsLoading(true);
      setNextBtn(true);
      setIsLoading(false);
    
  }

  function selectRecipient(e) {
    setRecipientID(e.target.value);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-lg">
        <h1 className="text-center text-2xl font-bold text-primary sm:text-3xl">
          Withdraw funds to your bank
        </h1>

        <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
          You can send Naira to any of your saved accounts in your list
        </p>

        {nextBtn === false ? (
          <>
            <form
              onSubmit={onSubmitHandler}
              className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
            >
              <p className="text-center text-lg font-medium">
                Fill in the Details
              </p>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="SelectRecipient"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  Choose a saved account{" "}
                </label>

                <div>
                  <select
                    id="SelectRecipient"
                    required
                    onChange={selectRecipient}
                    className="h-10 rounded border-gray-300 text-sm w-44 border"
                  >
                    <option>Select Account</option>
                    {recipients.map((recipient, index) => {
                      return (
                        <option key={index} value={recipient.id}>
                          {recipient.fullName}
                        </option>
                      );
                    })}
                  </select>
                  <p className="text-left text-sm text-secondary-content p-2 hover:text-yellow-600">
                    <Link className="underline" to="/recipient">
                      Add bank account?
                    </Link>
                  </p>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="Amount"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  Amount{" "}
                </label>

                <input
                  type="number"
                  id="Amount"
                  placeholder="30000"
                  value={amount}
                  required
                  onChange={onChange}
                  name="amount"
                  className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
                />
              </div>

              <div className="">
                <label
                  htmlFor="TransactionDetail"
                  className="block text-sm font-medium text-white"
                >
                  {" "}
                  Transaction Details{" "}
                </label>

                <div className="">
                  <input
                    type="text"
                    id="TransactionDetail"
                    className="mt-1 w-full rounded-lg border-gray-200 bg-white text-gray-700 p-4 pe-12 text-sm shadow-sm"
                    placeholder="Daddy's gift for Xmas"
                    value={transactionDetail}
                    onChange={onChange}
                    name="transactionDetail"
                    required
                  />
                </div>
              </div>

              <button
                type="submit"
                className="block w-full rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium "
              >
                Next
              </button>
            </form>
          </>
        ) : (
          <form
            onSubmit={sendHandler}
            className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
          >
            <div className="col-span-6 mt-3">
              <label
                htmlFor="otp"
                className="block text-sm font-bold text-white"
              >
                {" "}
                Your 5 Digit PIN{" "}
              </label>

              <input
                type="number"
                id="otp"
                value={otp}
                required
                onChange={onChange}
                name="otp"
                maxLength="5"
                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-3"
              />
            </div>

            <p className="text-left text-sm text-gray-500">
              Forgot Pin?{" "}
              <Link className="underline" to="/resetotp">
                Reset
              </Link>
            </p>

            <button
              type="submit"
              className="block w-full rounded-lg bg-gray-900 px-5 py-3 text-sm font-medium text-white"
            >
              Send
            </button>
            <button
              type="button"
              className="block w-full rounded-lg bg-red-500 px-5 py-3 text-sm font-medium text-black"
              onClick={undoNext}
            >
              Back
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
export default BankTransfer;
