import myLogo from "../images/nairadomlogo.png";

function Footer() {
  const footerYear = new Date().getFullYear();

  return (
    <footer className="footer p-10 footer-center bg-white w-screen">
      <div className="text-center text-secondary">
        NairaDOM allows you grow Your wealth while investing in stable foreign
        businesses for Inflation-Beating Returns.
      </div>

      {/* <p className="text-secondary">
        NairaDom Office, Araromi phase 1, Sagamu,
        Nigeria
        <br /> <br /> contact@nairadom.com <br /> <br />
        +234 (813) 773 0189
      </p> */}
      <img src={myLogo} alt="logo" className=" h-11" />
      <p className="text-secondary">
        Copyright &copy; {footerYear} All rights reserved
      </p>
    </footer>
  );
}
export default Footer;
