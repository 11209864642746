import { StoreProvider } from "./context/storeContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./layout/Navbar";
import AppSetup from "./layout/AppSetup";
import Home from "./pages/Home";
import About from "./pages/About";
import Login from "./pages/login";
import Logout from "./components/Logout";
import Footer from "./components/Footer";
import Register from "./pages/Register";
import LoginVerification from "./components/LoginVerification";
import ResetPassword from "./pages/ResetPassword";
import ResetVerification from "./components/ResetVerification";
import Wallet from "./pages/Wallet";
import UserTransfer from "./components/UserTransfer";
import Recipient from "./pages/Recipient";
import BankTransfer from "./components/BankTransfer";
import Profile from "./pages/Profile";
import LoginActivities from "./components/LoginActivities";
import Transactions from "./pages/Transactions";
import CrytoInvoice from "./components/CrytoInvoice";
import TransactionItem from "./components/TransactionItem";
import UserVerification from "./components/UserVerification";
import ResetOtp from "./pages/ResetOtp";
import ResetOtpVerification from "./components/ResetOtpVerification";
import UsdWalletDeposit from "./components/UsdWalletDeposit";
import UsdWalletWithdraw from "./components/UsdWalletWithdraw";
import InvestmentPlans from "./components/InvestmentPlans";
import InvestmentPlanItem from "./components/InvestmentPlanItem";
import Subscriptions from "./pages/Subscriptions";
import SubscriptionItem from "./components/SubscriptionItem";
import NotFound from "./pages/NotFound";
import RegisterReferral from "./components/RegisterReferral";
import Invite from "./components/Invite";


function App() {
  return (
    <Router>
      <StoreProvider>
        <Navbar />
        <ToastContainer />
        <div className="flex flex-col justify-between h-screen">
          <AppSetup />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/invite" element={<Invite />} />
            <Route path="/investmentplans" element={<InvestmentPlans />} />
            <Route path="*" element={<NotFound />} />

            <Route path="/transaction/:id" element={<TransactionItem />} />
            <Route path="/register/:id" element={<RegisterReferral />} />
            <Route path="/subscription/:id" element={<SubscriptionItem />} />
            <Route
              path="/investmentplan/:id"
              element={<InvestmentPlanItem />}
            />
            <Route path="/usdwalletdeposit" element={<UsdWalletDeposit />} />
            <Route path="/usdwalletwithdraw" element={<UsdWalletWithdraw />} />
            <Route path="/userverification" element={<UserVerification />} />
            <Route path="/cryptodeposit" element={<CrytoInvoice />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/activities" element={<LoginActivities />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/banktransfer" element={<BankTransfer />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/recipient" element={<Recipient />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/apptransfer" element={<UserTransfer />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/loginverification" element={<LoginVerification />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route
              path="/resetpasswordverification"
              element={<ResetVerification />}
            />
            <Route
              path="/resetotpverification"
              element={<ResetOtpVerification />}
            />
            <Route path="/resetotp" element={<ResetOtp />} />
          </Routes>
          <Footer />
        </div>
      </StoreProvider>
    </Router>
  );
}

export default App;
